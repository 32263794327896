import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  IconButton,
  InputAdornment,
  Avatar,
  Fade,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SchoolIcon from '@mui/icons-material/School';

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
    setError(''); // Clear error when user types
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Mock login verification
    if (credentials.username === 'teacher' && credentials.password === 'password123') {
      // Mock login success with more detailed teacher data
      localStorage.setItem('teacher', JSON.stringify({
        id: 'T001',
        name: 'John Doe',
        photo: 'https://via.placeholder.com/150',
        isClassTeacher: true,
        classTeacherOf: '10-A',
        classesHandled: ['10-A', '10-B', '11-A'],
        subjects: {
          '10-A': ['Mathematics', 'Physics'],
          '10-B': ['Mathematics'],
          '11-A': ['Physics']
        }
      }));
      navigate('/dashboard');
    } else {
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(120deg, #e0f2f1 0%, #80cbc4 100%)',
        padding: { xs: 2, md: 0 }
      }}
    >
      <Container maxWidth="sm">
        <Fade in={true} timeout={1000}>
          <Paper
            elevation={8}
            sx={{
              p: { xs: 3, md: 4 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
              borderRadius: '16px',
              position: 'relative',
              overflow: 'hidden',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '4px',
                background: 'linear-gradient(90deg, #4db6ac, #26a69a, #009688)',
              }
            }}
          >
            <Avatar
              sx={{
                m: 2,
                bgcolor: '#009688',
                width: 56,
                height: 56,
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
              }}
            >
              <SchoolIcon fontSize="large" />
            </Avatar>

            <Typography 
              component="h1" 
              variant="h4" 
              sx={{ 
                mb: 1,
                fontWeight: 600,
                background: 'linear-gradient(45deg, #1a237e, #0097a7)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Teacher Portal
            </Typography>

            <Typography 
              variant="body1" 
              sx={{ 
                mb: 3, 
                color: 'text.secondary',
                textAlign: 'center' 
              }}
            >
              Welcome back! Please login to your account
            </Typography>

            <Box 
              component="form" 
              onSubmit={handleSubmit} 
              sx={{ 
                width: '100%',
                mt: 1 
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={credentials.username}
                onChange={handleChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon sx={{ color: 'text.secondary' }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    bgcolor: 'white'
                  }
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={credentials.password}
                onChange={handleChange}
                error={!!error}
                helperText={error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon sx={{ color: 'text.secondary' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    bgcolor: 'white'
                  }
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  borderRadius: '12px',
                  fontSize: '1rem',
                  textTransform: 'none',
                  background: 'linear-gradient(45deg, #00897b, #009688)',
                  boxShadow: '0 4px 12px rgba(0,150,136,0.4)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #00796b, #00897b)',
                    boxShadow: '0 6px 16px rgba(0,150,136,0.6)',
                  }
                }}
              >
                Sign In
              </Button>

              {/* Demo credentials hint */}
              <Typography 
                variant="body2" 
                color="text.secondary" 
                align="center"
                sx={{ 
                  mt: 2,
                  p: 2,
                  bgcolor: 'rgba(0, 0, 0, 0.02)',
                  borderRadius: '8px',
                  border: '1px dashed rgba(0, 0, 0, 0.1)'
                }}
              >
                Demo Credentials:<br />
                Username: <strong>teacher</strong><br />
                Password: <strong>password123</strong>
              </Typography>
            </Box>
          </Paper>
        </Fade>
      </Container>
    </Box>
  );
};

export default Login;
